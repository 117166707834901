import React from "react";

import { Grid, _ } from "gridjs-react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import TablePagination from "../../../Common/TablePagination";
import moment from "moment";

const Table = (props) => {
  // console.log('aaa',props.data)
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1">
                  {props.headingPreview}
                </h4>
              </CardHeader>

              <CardBody>
                <div id="table-gridjs">
                  <Grid
                    data={props.data}
                    columns={[
                      // {
                      //   name: 'Booking id',
                      //   width: '120px',
                      //   formatter: (cell, row) =>
                      //     _(
                      //       <input
                      //         className="form-check-input"
                      //         type="checkbox"
                      //         name="checkAll"
                      //         value="option6"
                      //       />,
                      //     ),
                      // },

                      {
                        name: "S.No.",
                        width: "60px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Booking id",
                        width: "120px",
                        formatter: (cell, row) =>
                          _(
                            <span
                              className="fw-semibold"
                              style={{
                                cursor: "pointer",
                                // textDecoration: 'underline',
                              }}
                              onClick={() =>
                                props.onClickHandler({
                                  id: `${cell}`,
                                  status: `VIEW`,
                                })
                              }
                            >
                              {cell}
                            </span>
                          ),
                      },

                      {
                        name: "Order id",
                        width: "170px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Payment id",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "First Name",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Last Name",
                        width: "150px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Phone",
                        width: "150px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Email",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },
                      {
                        name: "Bogo Offer",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },
                      {
                        name: "Guest Name",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Golf Course Name",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Created At",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Updated At",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Date of Play",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Time of Play",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Golf Address",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Golf Country",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Golf Region",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Status",
                        width: "120px",
                        formatter: (cell) =>
                          _(<span className="">{cell}</span>),
                      },

                      {
                        name: "Actions",
                        width: "120px",
                        formatter: (cell, row) =>
                          _(
                            <div className="d-flex justify-content-center flex-column">
                              {cell?.status === "PENDING" && (
                                <>
                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() =>
                                      props.onClickHandler({
                                        id: `${cell?.objectId}`,
                                        status: `PROCESSED`,
                                      })
                                    }
                                  >
                                    Process
                                  </span>

                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() =>
                                      props.onClickHandler({
                                        id: `${cell?.objectId}`,
                                        status: `CANCELLED`,
                                      })
                                    }
                                  >
                                    Cancel
                                  </span>
                                </>
                              )}
                              {cell?.status === "PENDING" && ((cell?.date_of_play.preferred+" "+ cell?.time_of_play.preferred) > moment().format('YYYY/MM/DD HH:mm')) && (
                                <>
                                  <span
                                    className="btn btn-sm btn-warning mb-1"
                                    onClick={() =>
                                      props.onClickHandler({
                                        id: `${cell?.objectId}`,
                                        status: `UPDATE`,
                                      })
                                    }
                                  >
                                    Update
                                  </span>

                                 
                                </>
                              )}
                              {cell?.status === "PROCESSED" && (
                                <>
                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() =>
                                      props.onClickHandler({
                                        id: `${cell?.objectId}`,
                                        status: `CONFIRMED`,
                                      })
                                    }
                                  >
                                    Confirmed
                                  </span>

                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() =>
                                      props.onClickHandler({
                                        id: `${cell?.objectId}`,
                                        status: `CANCELLED`,
                                      })
                                    }
                                  >
                                    Cancel
                                  </span>
                                </>
                              )}

                              {cell?.status === "CONFIRMED" && (
                                <>
                                  <span
                                    className="btn btn-sm btn-primary mb-1"
                                    onClick={() =>
                                      props.onClickHandler({
                                        id: `${cell?.objectId}`,
                                        status: `CANCELLED`,
                                      })
                                    }
                                  >
                                    Cancel
                                  </span>
                                </>
                              )}
                            </div>
                          ),
                      },
                    ]}
                    search={false}
                    sort={false}
                    fixedHeader={true}
                    height="400px"
                    pagination={{ enabled: false, limit: 5 }}
                  />
                </div>
                {props.children}
              </CardBody>

              {props?.data?.length > 0 && (
                <CardFooter>
                  <Row className="custom_pagination">
                    <Col>
                      <div className="d-flex justify-content-end">
                        <TablePagination
                          totalData={props?.totalData}
                          currentPage={props?.pageNumber}
                          onPageChangeHandler={props?.pageHandler}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Table;
